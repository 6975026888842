/*eslint-disable*/
import React, { useRef, useState } from 'react'
import "./homepage.css"
import * as htmlToImage from 'html-to-image';
import Papa from "papaparse";
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './componentToPrint';
import { templates } from '../cert_templates';
import certifyLogo from "./../certify-logo.svg";
import MOCK_DATA from "./../files/MOCK_DATA.csv";

const allowedExtensions = ["csv"];

const Popup = (props) => {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-box">
                <button onClick={() => props.setpop(false)} >x</button>
                <h3 style={{fontWeight: '400'}}>{props.count === props.total ? "Completed" : "Processing..."}</h3>
                <div className="count">
                    {props.count} / {props.total}
                </div>
            </div>
        </div>
    ) : null;
}
function Homepage() {
    const date = new Date();
    let time = date.getTime();
    
    const [pop, setpop] = useState(false);
    const [name, setname] = useState('');
    const [fileName, setFileName] = useState(`my-certificate${time}`);
    const [heading, setheading] = useState('');
    const [desc, setdesc] = useState('');
    const [author, setauthor] = useState('');
    const [logo, setlogo] = useState('');
    const [template, settemplate] = useState('template14');
    const [saveAs, setSaveAs] = useState('jpeg');
    const [theme, setTheme] = useState("dark");
    const [toggle, setToggle] = useState("single");
    const [count, setCount] = useState(0);
    const [file, setFile] = useState("");
    const [error, setError] = useState("");
    const [data, setData] = useState([]);

    const componentRef = useRef();
    const domEl = useRef(null);

    // const ref = useRef<HTMLDivElement>(null);
    // const toggleTheme =() => {
    //     if(theme === "dark") {
    //         setTheme("light");
    //     }
    //     else {
    //         setTheme("dark");
    //     }
    // }

    const downloadImage = async () => {
        var dataUrl = '';
        if (saveAs === "jpeg") {
            dataUrl = await htmlToImage.toJpeg(domEl.current, {pixelRatio: 2});
        }
        else if (saveAs === "png") {
            dataUrl = await htmlToImage.toPng(domEl.current, {pixelRatio: 2});
        }
        else if (saveAs === "svg") {
            dataUrl = await htmlToImage.toSvg(domEl.current, {pixelRatio: 2});
        }

        const link = document.createElement('a');
        link.download = `${fileName}.${saveAs}`;
        link.href = dataUrl;
        link.click();
    }

    const handleFileChange = (e) => {
        setError("");
        setData([]);

        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }

            setFile(inputFile);
        }
    };

    const handleParse = () => {
        if (!file) return setError("Enter a valid file");

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            parsedData?.pop();
            
            if(!parsedData[0]?.Name || !parsedData[0]?.FileName) {
                setError("No required column found");
                return;
            }else{
                setData(parsedData);
            }
        };
        reader.readAsText(file);
    };

    const handleStartDownloadBtn = () => {
        if (data.length) {
            handleAutomation();
        }
    }

    const handleAutomation = async() => {
        var i = 0;
        setpop(true);

        function myLoop() {
            setTimeout(async () => {
                setname(data[i]?.Name);
                setCount(i+1);
                setFileName(data[i]?.FileName);

                var dataUrl = '';
                if (saveAs === "jpeg") {
                    dataUrl = await htmlToImage.toJpeg(domEl.current, {pixelRatio: 2});
                }
                else if (saveAs === "png") {
                    dataUrl = await htmlToImage.toPng(domEl.current, {pixelRatio: 2});
                }
                else if (saveAs === "svg") {
                    dataUrl = await htmlToImage.toSvg(domEl.current, {pixelRatio: 2});
                }

                const link = document.createElement('a');
                link.download = `${data[i]?.FileName}.${saveAs}`;
                link.href = dataUrl;
                link.click();

                i++;
                if (i < data?.length) {
                    myLoop();
                }
            }, 1000)
        }
        myLoop();
    }

    const handleBulkToggler = () => {
        if (toggle === "single") {
            setToggle("bulk");
        }
        else {
            setToggle("single");
        }
        setname("");
    }

    return (
        <div className="main" style={{ background: (theme === "dark") ? "linear-gradient(#000, #222831, #000)" : "white" }}>
            <Popup trigger={pop} setpop={setpop} count={count} total={data?.length}>
            </Popup>
            <div className="header">
                <div className="left">
                    <img src={certifyLogo} width={220} />
                    {/* <h2 style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>certify.social</h2> */}
                    {/* <button id="toggler" onClick={()=>toggleTheme()}>{theme === 'light'?"Dark Theme":"Light Theme"}</button> */}
                </div>
                <div className="Middle">
                    <h4>Certificate Generator</h4>
                </div>
                <div className="right">
                    {toggle === "bulk" && data?.length ? <button className="info" onClick={() => setpop(true)}>show status</button> : null}
                    {/* <a href="https://github.com/vedant-jain03/certificate-generator">Contribute <ArrowUpwardIcon />  </a>
                    <a href="https://github.com/vedant-jain03/certificate-generator">Give us <StarOutlineIcon /> </a> */}
                </div>
            </div>
            <div className="maincontainer">
                <div className="leftmost">
                    <h3 style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Templates</h3>
                    {/* <div className={`templates ${template==='template1'?"active":""}`} onClick={()=>settemplate("template1")} >
                        <img src={template1} alt="" />
                    </div>
                    <div className={`templates ${template==='template2'?"active":""}`} onClick={()=>settemplate("template2")} >
                        <img src={template2} alt="" />
                    </div>
                    <div className={`templates ${template==='template3'?"active":""}`} onClick={()=>settemplate("template3")} >
                        <img src={template3} alt="" />
                    </div>
                    <div className={`templates ${template==='template4'?"active":""}`} onClick={()=>settemplate("template4")} >
                        <img src={template4} alt="" />
                    </div>
                    <div className={`templates ${template==='template5'?"active":""}`} onClick={()=>settemplate("template5")} >
                        <img src={template5} alt="" />
                    </div>
                    <div className={`templates ${template==='template6'?"active":""}`} onClick={()=>settemplate("template6")} >
                        <img src={template6} alt="" />
                    </div>
                    <div className={`templates ${template==='template7'?"active":""}`} onClick={()=>settemplate("template7")} >
                        <img src={template7} alt="" />
                    </div>
                    <div className={`templates ${template==='template8'?"active":""}`} onClick={()=>settemplate("template8")} >
                        <img src={template8} alt="" />
                    </div>
                    <div className={`templates ${template==='template9'?"active":""}`} onClick={()=>settemplate("template9")} >
                        <img src={template9} alt="" />
                    </div>
                    <div className={`templates ${template==='template10'?"active":""}`} onClick={()=>settemplate("template10")} >
                        <img src={template10} alt="" />
                    </div>
                    <div className={`templates ${template==='template11'?"active":""}`} onClick={()=>settemplate("template11")} >
                        <img src={template11} alt="" />
                    </div>
                    <div className={`templates ${template==='template12'?"active":""}`} onClick={()=>settemplate("template12")} >
                        <img src={template12} alt="" />
                    </div> */}
                    <div className={`templates ${template === 'template13' ? "active" : ""}`} onClick={() => settemplate(templates[12]?.name)} >
                        <img src={templates[12]?.img} alt="" />
                    </div>
                    <div className={`templates ${template === 'template14' ? "active" : ""}`} onClick={() => settemplate(templates[13]?.name)} >
                        <img src={templates[13]?.img} alt="" />
                    </div>
                    <div className={`templates ${template === 'template15' ? "active" : ""}`} onClick={() => settemplate(templates[14]?.name)} >
                        <img src={templates[14]?.img} alt="" />
                    </div>
                    <div className={`templates ${template === 'template16' ? "active" : ""}`} onClick={() => settemplate(templates[15]?.name)} >
                        <img src={templates[15]?.img} alt="" />
                    </div>
                    <div className={`templates ${template === 'template17' ? "active" : ""}`} onClick={() => settemplate(templates[16]?.name)} >
                        <img src={templates[16]?.img} alt="" />
                    </div>
                    <div className={`templates ${template === 'template18' ? "active" : ""}`} onClick={() => settemplate(templates[17]?.name)} >
                        <img src={templates[17]?.img} alt="" />
                    </div>
                </div>
                <div className="middle">
                    <div ref={domEl}>
                        <ComponentToPrint ref={componentRef} name={name} heading={heading} desc={desc} author={author} logo={logo} template={template} />
                    </div>
                </div>
                <div className="right">
                    <div className="form">
                        {/* <div className="input-box">
                            <span className="details" style={{color: (theme === "dark")?"white":"rgb(28, 39, 43)"}}>Heading</span>
                            <input type="text" placeholder="Enter heading" onChange={e => { setheading(e.target.value) }} />
                        </div> */}
                        <span style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)", marginBottom: "10px", fontWeight: 500 }}>Bulk creation</span>
                        <div>
                            <label className="switch">
                                <input value={toggle} type="checkbox" onChange={handleBulkToggler} />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        {toggle === "bulk" ?
                        <>
                            <div className="input-box" style={{ color: "#fff" }}>
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Input CSV File</span>
                                <input
                                    onChange={handleFileChange}
                                    id="csvInput"
                                    name="file"
                                    type="File"
                                    style={{ padding: 0 }}
                                />
                                <button onClick={handleParse} style={{padding: "5px", borderRadius: "5px"}} disabled={!file}>submit</button>
                                <div style={{ color: "red", margin: "5px 0" }}>{error ? error : null}</div>
                            </div>
                            <div style={{ color: "#fff", margin: "10px 0", border: "1px solid #666", padding: "10px", color: "#eed202" }}>
                                The csv file you are inputing must have 2 columns, The column of user's name must be titled as <b>"Name"</b> and the column of file name must be titled as <b>"FileName"</b>.
                                <div>click <a 
                                    href={MOCK_DATA} 
                                    download={"MOCK_DATA"} 
                                    target='_blank'
                                    rel='noreferrer'
                                    style={{textDecoration: "underline", color: "#4f9eff", cursor: "pointer"}}
                                    > here</a> to download sample csv.
                                </div>
                            </div>
                            </>:
                            <>
                                <div className="input-box">
                                    <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Name</span>
                                    <input id="nameInput" name="nameInput" type="text" placeholder="Enter participant Name" onChange={e => { setname(e.target.value) }} />
                                </div>
                                <div className="input-box">
                                    <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>File Name</span>
                                    <input id="fileName" name="fileName" type="text" placeholder="Enter File Name" onChange={e => { setFileName(e.target.value) }} />
                                </div>
                            </>}

                                <div className="input-box">
                                    <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Save as</span>
                                    <select id="save-as" onChange={(e) => setSaveAs(e.target.value)}>
                                        <option value="jpeg">jpeg</option>
                                        <option value="png">png</option>
                                        <option value="svg">svg</option>
                                    </select>
                                </div>

                        {/* <div className="input-box">
                            <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Description</span>
                            <textarea type="text" placeholder="Enter Description" onChange={e => setdesc(e.target.value)} />
                        </div>
                        <div className="input-box">
                            <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Author Name</span>
                            <input type="text" placeholder="Enter Author Name" onChange={e => setauthor(e.target.value)} />
                        </div>
                        <div className="input-box">
                            <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Logo URL</span>
                            <input type="text" placeholder="Enter logo URL" onChange={e => setlogo(e.target.value)} />
                        </div> */}

                        {toggle === "bulk" ?
                            <button className="generate" onClick={handleStartDownloadBtn} disabled={error || !file || !data.length}>Start downloading</button> :
                            <>
                                <ReactToPrint
                                    trigger={() => <button className="generate" >Print this out!</button>}
                                    content={() => componentRef.current}
                                />
                                <button id="jpegButton" name="jpegButton" className="generate" type="button" onClick={downloadImage}>Download</button>
                            </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage
