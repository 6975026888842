/*eslint-disable*/
import React from 'react';
import './App.css';
import Homepage from "./components/Homepage";
// import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Homepage />
      {/* <Footer /> */}
    </div>
  )
}

export default App;
