import template1 from "../cert_templates/Template1.png";
import template2 from "../cert_templates/Template2.png";
import template3 from "../cert_templates/Template3.png";
import template4 from "../cert_templates/Template4.jpeg";
import template5 from "../cert_templates/Template5.png";
import template6 from "../cert_templates/Template6.png";
import template7 from "../cert_templates/Template7.png";
import template8 from "../cert_templates/Template8.png";
import template9 from "../cert_templates/Template9.gif";
import template10 from "../cert_templates/Template10.jpg";
import template11 from "../cert_templates/Template11.png";
import template12 from "../cert_templates/Template12.png";

import KSUM from "../cert_templates/KSUM-template.png";
import KDW from "../cert_templates/KDW-template.png";
import OPEN from "../cert_templates/OPEN-template.png";
import KMA from "../cert_templates/KMA-template.png";
import TIECON from "../cert_templates/TIECON-template.png";
import SeedingKerala from "../cert_templates/seeding-kerala.png";

export const templates = [
    { name: "template1", img: template1 },
    { name: "template2", img: template2 },
    { name: "template3", img: template3 },
    { name: "template4", img: template4 },
    { name: "template5", img: template5 },
    { name: "template6", img: template6 },
    { name: "template7", img: template7 },
    { name: "template8", img: template8 },
    { name: "template9", img: template9 },
    { name: "template10", img: template10 },
    { name: "template11", img: template11 },
    { name: "template12", img: template12 },
    { name: "template13", img: KMA },
    { name: "template14", img: TIECON },
    { name: "template15", img: KDW },
    { name: "template16", img: KSUM },
    { name: "template17", img: OPEN },
    { name: "template18", img: SeedingKerala },
]